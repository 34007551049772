<template>
  <div>
    <v-card class="user-main-settings" color="appBackGround" elevation="0">
      <v-tabs v-model="tab" slider-size="1" background-color="appBackGround">
        <v-tabs-slider class="ml-3" color="primary"></v-tabs-slider>
        <v-tab
          class="font-12 fw-500 letter-space text-transform-none"
          v-for="(item, i) in items"
          :key="i"
          active-class="primary--text"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, i) in items" :key="i">
            <component v-bind:is="item.content"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import PersonalDetails from "../user/PersonalDetails.vue";
import ApiKeys from "../user/ApiKeys.vue";

export default {
  components: { PersonalDetails, ApiKeys },
  data() {
    return {
      tab: null,
      items: [
        { name: "Personal Details", content: "PersonalDetails" },
        { name: "API Keys", content: "ApiKeys" },
      ],
    };
  },
};
</script>

<style>
.user-content {
  margin-top: 48px !important;
  margin-left: -65px !important;
}
.theme--light.v-divider {
  border-color: #e8ebf6 !important;
}
.user-details-field fieldset {
  border: 1px solid #e1e4ec !important;
  border-radius: 6px;
}
.user-details-field .v-input__slot {
  max-width: 360px !important;
}
.user-switch .v-label {
  font-size: 12px !important;
  color: #8990a2 !important;
}
.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {
  width: 40px !important;
  height: 23px !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px !important;
}
.user-btns {
  border-radius: 6px !important;
}
.v-tabs-slider-wrapper {
  max-width: 90px !important;
}
.v-tab:before {
  background-color: #fafafa !important;
}
.user-main-settings {
  padding: 0px 12px 0px 12px !important;
}
</style>
